import React from 'react'
import { LoadingIcon, ArrowRightIcon } from '../Icons'
import { useSelector } from 'react-redux'

interface SubmitButtonProps {
  label: string
  isLoading?: boolean
  onClick: () => void
  showNext?: boolean
}

export function SubmitButton({
  label,
  isLoading = false,
  onClick,
  showNext = false
}: SubmitButtonProps) {
  const brand = useSelector((store) => store.global.brand.name)
  return (
    <button
      type="submit"
      className={`
        flex w-full h-11 mb-[20px] py-[10px] items-center justify-center gap-[10px] rounded-[8px] text-center font-[600] text-[16px] leading-[24px] bg-primary-200 dark:bg-primary-600 hover:bg-primary-300 dark:hover:bg-primary-500 shadow-none
        ${brand === 'jdm' ? 'dark:hover:text-black bg-primary-400 hover:bg-primary-500 dark:bg-primary-300 dark:hover:bg-primary-500' : 'text-white bg-primary-700 dark:bg-primary-300 hover:bg-primary-900 dark:hover:bg-primary-500'}`}
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          {label}
          {showNext && <ArrowRightIcon className="ml-[10px] w-5 h-5 stroke-white" />}
        </>
      )}
    </button>
  )
}
