import React, { useState, useRef, useEffect } from 'react'
import { modalEventHandler } from '../../../js/helpers/modalEventHandler'
import { SelectionOption } from '../Selection'
import { ChevronDownIcon, LoadingIcon } from '../Icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelectCompanyMutation } from '../../services/cpanel'

type Company = { accountId: number; name: string; domain: string; customerId: number }

interface CompaniesProps {
  companies: Company[] | undefined
  onSelect: (company: Company) => void
  selected: string
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
}

export function Companies({
  companies,
  onSelect,
  selected,
  isLoading,
  setIsLoading
}: CompaniesProps) {
  const [open, setOpen] = useState(false)
  const popupRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  const [selectCompany, { isLoading: isCompanyLoading, isSuccess }] = useSelectCompanyMutation()

  const handleOpenClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (isSuccess) navigate(0)
  }, [isSuccess])

  useEffect(() => {
    return modalEventHandler(open, handleOpenClick)
  }, [open])

  useEffect(() => {
    setIsLoading(false)
  }, [location])

  const handleSelectCompany = async (company) => {
    setOpen(false)
    setIsLoading(true)
    onSelect(company.name)
    try {
      selectCompany(company.accountId)
    } catch (err) {
      console.error('Failed to fetch company data: ', err)
    }
  }

  return (
    <div
      onClick={() => setOpen(!open)}
      ref={popupRef}
      className="group px-[20px] py-[10px] relative flex gap-[8px] rounded-[24px] hover:bg-secondary-100 dark:hover:bg-primary-600 font-inter text-[14px] font-[600] text-gray-500 dark:text-gray-200 hover:dark:text-gray-100 hover:text-primary-500 cursor-pointer transition duration-100"
    >
      <div className="flex items-center w-full">
        <button type="button" className="w-full text-start">
          <span>
            {isLoading ? (
              <LoadingIcon className="self-center animate-spin h-4 w-4 dark:text-white text-primary-900" />
            ) : (
              selected
            )}
          </span>
        </button>
        {open && (
          <>
            <div className="visible sm:hidden transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] opacity-100" />
            <div className="text-nowrap w-full rounded-[12px] fixed sm:absolute m-0 right-0 z-50 px-[16px] sm:px-0 pb-[32px] sm:pb-0 bottom-[0px] sm:bottom-auto top-auto sm:top-[50px] bg-white dark:bg-gray-700 font-inter font-[500] text-[16px] leading-[24px] text-gray-700 dark:text-gray-100 shadow-lightElev1 dark:shadow-darkElev1 animate-slide-up sm:animate-fade-in">
              <ul className="py-1 rounded-[12px]" role="none">
                {companies.map((company) => (
                  <SelectionOption
                    key={company.accountId}
                    text={company.name}
                    onClick={() => handleSelectCompany(company)}
                  />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
      <span className="text-gray-700 dark:text-gray-200 group-hover:text-primary-500 group-hover:dark:text-gray-100">
        <ChevronDownIcon className="w-5 h-5" />
      </span>
    </div>
  )
}
