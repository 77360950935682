import React, { useState, useEffect, memo } from 'react'
import { useLocation, Link, Outlet } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'

export const settingsRoutes = [
  { name: 'contact', label: 'Contact' },
  { name: 'password', label: 'Password' },
  { name: 'company', label: 'Company' },
  { name: 'preferences', label: 'Preferences' },
  { name: 'connections', label: 'Connections' },
  { name: 'website', label: 'Website' }
]

export const SettingsTabs = memo((props) => {
  const { pathname } = useLocation()
  const [value, setValue] = useState('contact')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  useEffect(() => {
    const tabValue =
      settingsRoutes.find((route) => pathname.includes(route?.name))?.name || 'contact'
    setValue(tabValue)
  }, [pathname])

  return (
    <div className="pt-[16px] self-start " style={{ width: '-webkit-fill-available' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            className="dark:text-white"
            onChange={handleChange}
            aria-label="customer tabs"
            variant="scrollable"
          >
            {settingsRoutes.map((route, index) => (
              <Tab
                key={index}
                label={route.label}
                value={route.name}
                component={Link}
                to={`${route.name}`}
              />
            ))}
          </TabList>
        </Box>
        {settingsRoutes.map((_, index) => (
          <TabPanel key={index} value={index.toString()} sx={{ padding: '0px' }} />
        ))}
      </TabContext>
      <Outlet context={props} />
    </div>
  )
})
