import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateFilter } from '../DateFilter'
import { ThemeToggler } from '../ThemeToggler'
import { BellButton } from '../Notifications'
import { getPeriodFromDateRange } from '../../helpers/getPeriodFromDateRange'
import { setDateFilter } from '../../store/global'
import { PageType } from '../../pages/PageType'
import { TemporaryButton } from './TemporaryButton'
import { Companies } from '../Companies'

interface HeaderProps {
  pageType: PageType
  setFullSize: (fullSize: boolean) => void
}

export function Header({ pageType, setFullSize }: HeaderProps) {
  const { from, to } = useSelector((store) => store.global.dateFilter)
  const customerId = useSelector((store) => store.global.customer.id)
  const companyName = useSelector((store) => store.global.customer.name)
  const companies = useSelector((store) => store.global.companies)

  const [scrolled, setScrolled] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(companyName)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const onScroll = () => {
    setScrolled(window.pageYOffset > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const onSelectDateRange = ({ units, period }) => {
    dispatch(
      setDateFilter({
        period,
        period_many: units
      })
    )
  }

  const onSelectCompany = (company) => {
    setSelectedCompany(company)
  }

  const brand = useSelector((store) => store.global.brand)
  const darkMode = useSelector((store) => store.global.darkMode)

  const hasDateFilter = [
    PageType.Dashboard,
    PageType.Ads,
    PageType.Facebook,
    PageType.Campaign
  ].includes(pageType)

  return (
    <div
      className={`bg-white dark:bg-gray-800 fixed z-20 top-0 right-0 left-0 pl-[60px] md:pl-[105px] pr-6 col-span-full row-span-2 md:row-span-1 flex flex-wrap items-center md:justify-between justify-start md:flex-shrink-0 py-4 transition-all ${
        scrolled ? 'shadow-md' : ''
      }`}
    >
      <div className="flex flex-grow items-center justify-start">
        <img
          className="items-center justify-center h-[32px] w-auto hidden sm:block"
          src={darkMode ? brand.logoDark : brand.logo}
          alt={`${brand.name} Logo`}
        />
        <img
          className="items-center justify-center h-[32px] w-auto visible sm:hidden"
          src="/images/fav/favicon-192.png"
          alt={`${brand.name} Logo`}
        />
      </div>

      {customerId === '21030807364' && <TemporaryButton />}
      <div className="flex flex-grow justify-center items-center">
        {companies.length > 1 && (
          <Companies
            companies={companies}
            onSelect={onSelectCompany}
            selected={selectedCompany}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
      <div className="flex flex-grow justify-end flex-wrap space-x-2 md:space-x-4">
        <div className="flex gap-[8px] md:gap-[16px]">
          <ThemeToggler />
          <BellButton setFullSize={setFullSize} />
          {hasDateFilter && (
            <DateFilter selected={getPeriodFromDateRange(from, to)} onSelect={onSelectDateRange} />
          )}
        </div>
      </div>
    </div>
  )
}
