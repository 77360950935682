import React from 'react'
import { GettingDataReadyIcon } from './GettingDataReadyIcon'

export function GettingDataReady() {
  return (
    <div className="col-span-full pt-2 mb-4 xl:mb-2">
      <div className="items-center justify-evenly">
        <div className="animate-grow-in flex w-full justify-self-start font-sans font-[400] text-[14px] text-gray-600 dark:text-white leading-[20px] bg-white dark:bg-gray-800 rounded-[16px] dark:text-base-50 items-center justify-center p-4 text-sm sm:text-base sm:p-6 xl:p-8 mb-4 h-full mb-4">
          <div className="flex items-center justify-center lg:justify-items-start">
            <span className="text-lg font-semibold text-base-900 dark:text-base-50 sm:text-xl tracking-tight">
              We are currently working hard to collate your metrics
            </span>
          </div>
          <div className="flex items-center justify-center lg:justify-items-start">
            <GettingDataReadyIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
