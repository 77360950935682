import React from 'react'
import { LoadingTable } from '../Loading'

type PanelProps = {
  title?: string
  children?: React.ReactNode
  loading?: boolean
  toggleText?: string | null
  onToggleClick?: () => void
  variant?: 'default' | 'settings'
}

export function Panel({
  title = null,
  children = null,
  loading = false,
  toggleText = null,
  onToggleClick = () => {},
  variant = 'default'
}: PanelProps) {
  return (
    <div
      className={`animate-slide-left dark:text-white h-full ${variant === 'settings' ? `mt-6` : `bg-white dark:bg-gray-800 rounded-[16px] p-4 xl:p-6`}`}
    >
      <div className="flex justify-between mb-4">
        <h3 className="text-lg md:text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h3>

        {toggleText && (
          <div className="flex-shrink-0">
            <button
              type="button"
              className="p-2 text-sm font-medium rounded-[16px] text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-base-700"
              onClick={onToggleClick}
            >
              {toggleText}
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {loading && <LoadingTable />}
        {!loading && children}
      </div>
    </div>
  )
}
