import React, {useState, useRef, useEffect} from 'react'
import { useRouteError } from 'react-router-dom'

export function ErrorPage() {
  const [domain, setDomain] = useState('')
  const [y1, setY1] = useState(160)
  const [y2, setY2] = useState(100)
  const [y3, setY3] = useState(100)
  const [y1Forward, setY1Forward] = useState(true)
  const [y2Forward, setY2Forward] = useState(false)
  const [y3Forward, setY3Forward] = useState(true)

  const visorRef = useRef(null)
  const cordRef = useRef(null)

  const error = useRouteError()

  useEffect(() => {
    console.error(error)
  }, [])

  useEffect(() => {
    setDomain(window.location.origin)
    drawVisor()
    const animationFrame = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(animationFrame)
  }, [y1, y2, y3])

  const drawVisor = () => {
    const visor = visorRef.current.getContext('2d')
    visor.beginPath()
    visor.moveTo(5, 45)
    visor.bezierCurveTo(15, 64, 45, 64, 55, 45)
    visor.lineTo(55, 20)
    visor.bezierCurveTo(55, 15, 50, 10, 45, 10)
    visor.lineTo(15, 10)
    visor.bezierCurveTo(15, 10, 5, 10, 5, 20)
    visor.lineTo(5, 45)
    visor.fillStyle = '#2f3640'
    visor.strokeStyle = '#f5f6fa'
    visor.fill()
    visor.stroke()
  }

  const animate = () => {
    const cord = cordRef.current.getContext('2d')
    cord.clearRect(0, 0, window.innerWidth, window.innerHeight)

    cord.beginPath()
    cord.moveTo(130, 170)
    cord.bezierCurveTo(250, y1, 345, y2, 400, y3)
    cord.strokeStyle = 'white'
    cord.lineWidth = 8
    cord.stroke()

    setY1(y1Forward ? y1 + 1 : y1 - 1)
    setY2(y2Forward ? y2 + 1 : y2 - 1)
    setY3(y3Forward ? y3 + 1 : y3 - 1)

    if (y1 === 100 || y1 === 300) setY1Forward(!y1Forward)
    if (y2 === 100 || y2 === 310) setY2Forward(!y2Forward)
    if (y3 === 100 || y3 === 317) setY3Forward(!y3Forward)
  }

  return (
    <div className="h-full w-full overflow-hidden">
      <div className="absolute -top-16 -left-72 h-[850px] w-[880px] rounded-full bg-gradient-to-r from-stone-300 to-neutral-500 shadow-2xl shadow-stone-900"></div>
      <div className="moon__crater moon__crater1"></div>
      <div className="moon__crater moon__crater2"></div>
      <div className="moon__crater moon__crater3"></div>
      <div className="star star1"></div>
      <div className="star star2"></div>
      <div className="star star3"></div>
      <div className="star star4"></div>
      <div className="star star5"></div>

      <div className="error space-y-2">
        <div className="mb-6 -ml-6 text-9xl">{error.status}</div>
        {error && <div className="text-sm font-bold text-red-700">{error.message}</div>}
        <div className="text-5xl">Hmmm...</div>
        <div>It looks like one of our developers fell asleep</div>
        <div>We will wake them with constant alerts until this is fixed!</div>
        <div className="space-x-2 space-y-2">
          <a
            href={domain}
            className="group inline-flex items-center rounded-md bg-secondary-500 px-3 py-2 font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:text-opacity-90 hover:opacity-90"
          >
            Home Page
          </a>
        </div>
      </div>

      <div className="astronaut">
        <div className="astronaut__backpack"></div>
        <div className="astronaut__body"></div>
        <div className="astronaut__body__chest h-10 w-12 bg-[url('/images/logo_dark.svg')] bg-center bg-no-repeat"></div>
        <div className="astronaut__arm-left1"></div>
        <div className="astronaut__arm-left2"></div>
        <div className="astronaut__arm-right1"></div>
        <div className="astronaut__arm-right2"></div>
        <div className="astronaut__arm-thumb-left"></div>
        <div className="astronaut__arm-thumb-right"></div>
        <div className="astronaut__leg-left"></div>
        <div className="astronaut__leg-right"></div>
        <div className="astronaut__foot-left"></div>
        <div className="astronaut__foot-right"></div>
        <div className="astronaut__wrist-left"></div>
        <div className="astronaut__wrist-right"></div>

        <div className="astronaut__cord w-screen overflow-x-hidden">
          <canvas ref={cordRef} id="cord" height="500px" width="500px"></canvas>
        </div>

        <div className="astronaut__head">
          <canvas ref={visorRef} id="visor" width="60px" height="60px"></canvas>
          <div className="astronaut__head-visor-flare1"></div>
          <div className="astronaut__head-visor-flare2"></div>
        </div>
      </div>
    </div>
    )
}
