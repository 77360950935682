import React from 'react'
import { BreadcrumbComponent } from '../components/Breacrumbs/BreadcrumbComponent.js'
import { SettingsTabs } from '../components/Tabs/SettingsTabs'

export const SettingsPage = () => {
  return (
    <div className="font-secondary flex flex-col px-2 md:ml-0 pt-3">
      <BreadcrumbComponent />
      <div className="flex flex-col gap-[8px] pt-[16px] pb-[16px] justify-center">
        <div className="flex flex-row gap-[10px] items-center">
          <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px] pr-[16px]">
            Settings
          </h1>
        </div>
        <p className="mt-1 text-sm font-normal text-base-400 dark:text-white">
          Manage your settings and preferences
        </p>
      </div>
      <SettingsTabs />
    </div>
  )
}
