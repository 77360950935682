import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { NavLink, useLocation } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { PresentationChartIcon } from '../Icons'
import { formatGoogleAdsId } from '../../helpers/formatGoogleAdsId'
import { settingsRoutes } from '../../components/Tabs/SettingsTabs'

const baseSpanClass =
  'text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100 hover:underline duration-100 transition'

export function BreadcrumbComponent() {
  const { pathname } = useLocation()
  const finalUrlParam = pathname.split('/').pop()
  const isAds = pathname.includes('ads')
  const isCampaign = pathname.includes('campaign')
  const isSeo = pathname.includes('seo')
  const isSettings = pathname.includes('settings')

  const settingsTab = isSettings
    ? settingsRoutes.find((route) => pathname.includes(route?.name))?.label || 'Contact'
    : null

  const adsId = isCampaign ? pathname.split('ads/')[1].split('/campaign')[0] : ''

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      <NavLink key="1" to="/cpanel" underline="none">
        <PresentationChartIcon className={`w-5 h-5 ${baseSpanClass}`} />
      </NavLink>
      {!isSettings && (
        <NavLink key="2" to={isCampaign ? '/cpanel/reports/ads' : pathname} underline="none">
          <span className={`${baseSpanClass} ${isSeo ? 'uppercase' : 'capitalize'}`}>
            {isAds ? 'Google Ads' : finalUrlParam}
          </span>
        </NavLink>
      )}
      {isCampaign && (
        <NavLink key="3" to="/cpanel/reports/ads" underline="none">
          <span className={`${baseSpanClass} ${isSeo ? 'uppercase' : 'capitalize'}`}>
            {formatGoogleAdsId(adsId)}
          </span>
        </NavLink>
      )}
      {isCampaign && (
        <NavLink key="4" href={pathname} underline="none">
          <span className={baseSpanClass}>Campaigns</span>
        </NavLink>
      )}
      {isSettings && (
        <NavLink key="2" to="/cpanel/settings" underline="none">
          <span className={baseSpanClass}>Settings</span>
        </NavLink>
      )}
      {isSettings && settingsTab && (
        <NavLink key="3" to={pathname} underline="none">
          <span className={baseSpanClass}>{settingsTab}</span>
        </NavLink>
      )}
    </Breadcrumbs>
  )
}
