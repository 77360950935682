import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../store/global'
import { Panel } from '../Panel'
import { InputField } from '../Onboarding/InputField'
import { SubmitButton } from '../Buttons/index'
import { useGetCompanyMutation, useUpdateCompanyMutation } from '../../services/settings'
import { useNavigate, useLocation } from 'react-router-dom'
import { validateCompanyName, validateCompanyWebsite } from '../../../../utils/validators'

export function CompanyUpdate() {
  const navigate = useNavigate()
  const location = useLocation()

  const [getCompany, { isLoading: isLoadingCompany, isSucess: isSuccessCompany }] =
    useGetCompanyMutation()
  const [updateCompany, { isLoading, isSuccess }] = useUpdateCompanyMutation()

  const [name, setName] = useState<string>('')
  const [website, setWebsite] = useState<string>('')

  const [isPageLoading, setIsPageLoading] = useState(false)

  const dispatch = useDispatch()
  const hasErrors = !validateCompanyName(name) || !validateCompanyWebsite(website)

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          title: 'Success',
          subtext: 'Company information has been updated.',
          type: 'success'
        })
      )
      navigate(0) // This is to refresh the company name on the header : cookie is refreshed with api call
    }
  }, [isSuccess])

  useEffect(() => {
    setIsPageLoading(false)
  }, [location])

  useEffect(() => {
    const getCompanyData = async () => {
      const res = await getCompany()
      const { name, website } = res.data.data
      setName(name)
      setWebsite(website)
    }
    getCompanyData()
  }, [])

  const onUpdateCompanyClick = () => {
    setIsPageLoading(true)
    if (hasErrors || isLoading) {
      dispatch(
        addToast({
          title: 'Invalid comapany name or website address',
          subtext: 'Please check your company name and website address and try again.',
          type: 'error'
        })
      )
      setIsPageLoading(false)
      return
    }
    updateCompany({ name, website })
  }

  return (
    <Panel title="Update Company Information" variant="settings">
      <div className="flex flex-col gap-[24px] justify-self-center justify-items-center pt-[16px] self-start w-full max-w-[344px]">
        <InputField
          name="companyName"
          label="Company Name"
          type="text"
          value={name}
          onChange={setName}
          hasError={name && !validateCompanyName(name)}
          placeholder="Enter your company name"
        />
        <InputField
          name="companyWebsite"
          label="Company Website"
          type="text"
          value={website}
          onChange={setWebsite}
          hasError={website && !validateCompanyWebsite(website)}
          placeholder="Enter your company website"
        />
        <SubmitButton
          isLoading={isPageLoading}
          label="Save Update"
          onClick={onUpdateCompanyClick}
        />
      </div>
    </Panel>
  )
}
