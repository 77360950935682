import React, { useState } from 'react'
import { IconButton, Popover, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'

interface CopiedProps {
  toCopy: string
}

export function Copied({ toCopy }: CopiedProps) {
  const [ticked, setTicked] = useState<boolean>(false)
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

  const handleCopyBtn = (e) => {
    setTicked(true)
    setOpenPopover(e.currentTarget)
    navigator.clipboard.writeText(toCopy)

    setTimeout(() => {
      setTicked(false)
      setOpenPopover(null)
    }, 2000)
  }

  return (
    <>
      <IconButton color="default" onClick={(e) => handleCopyBtn(e)}>
        {ticked ? (
          <DoneIcon sx={{ width: '20px' }} color="success" />
        ) : (
          <ContentCopyIcon sx={{ width: '20px' }} />
        )}
      </IconButton>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={() => setOpenPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Typography onMouseLeave={() => setOpenPopover(null)} sx={{ p: 2 }}>
          Copied!
        </Typography>
      </Popover>
    </>
  )
}
