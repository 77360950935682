import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../store/global'
import { Panel } from '../Panel'
import { InputField } from '../Onboarding/InputField'
import { SubmitButton } from '../Buttons/index'
import { useGetContactMutation, useUpdateContactMutation } from '../../services/settings'
import { validateEmail, validatePhone, validateName } from '../../../../utils/validators'

export function ContactUpdate() {
  const [getContact, { isLoading: isLoadingContact, isSucess: isSuccessContact }] =
    useGetContactMutation()
  const [updateContact, { isLoading, isSuccess }] = useUpdateContactMutation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const dispatch = useDispatch()
  const hasErrors =
    !validateEmail(email) ||
    !validatePhone(phone) ||
    !validateName(firstName) ||
    !validateName(lastName)

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          title: 'Success',
          subtext: 'Contact information has been updated.',
          type: 'success'
        })
      )
    }
  }, [isSuccess])

  useEffect(() => {
    const getUserContact = async () => {
      const res = await getContact()
      const { firstName, lastName, email, phone } = res.data.data
      setFirstName(firstName)
      setLastName(lastName)
      setEmail(email)
      setPhone(phone)
    }
    getUserContact()
  }, [])

  const onUpdateContactClick = () => {
    if (hasErrors || isLoading) {
      dispatch(
        addToast({
          title: 'Invalid name, email address or phone number',
          subtext: 'Please check your name, email address and phone number and try again.',
          type: 'error'
        })
      )
      return
    }
    updateContact({ firstName, lastName, email, phone })
  }

  return (
    <Panel title="Update Contact Information" variant="settings">
      <div className="flex flex-col gap-[24px] justify-self-center justify-items-center pt-[16px] self-start w-full max-w-[344px]">
        <InputField
          name="firstName"
          label="First Name"
          type="text"
          value={firstName}
          onChange={setFirstName}
          hasError={firstName && !validateName(firstName)}
          placeholder="Enter your first name"
        />
        <InputField
          name="lastName"
          label="Last Name"
          type="text"
          value={lastName}
          onChange={setLastName}
          hasError={lastName && !validateName(lastName)}
          placeholder="Enter your last name"
        />
        <InputField
          name="email"
          label="Email"
          type="email"
          placeholder="Enter your email address"
          hasError={email && !validateEmail(email)}
          value={email}
          onChange={setEmail}
          disabled={true}
        />
        <InputField
          name="phone"
          label="Phone"
          type="tel"
          value={phone}
          onChange={setPhone}
          hasError={phone && !validatePhone(phone)}
          placeholder="Enter your phone number"
        />
        <SubmitButton isLoading={isLoading} label="Save Update" onClick={onUpdateContactClick} />
      </div>
    </Panel>
  )
}
