import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Panel } from '../Panel'
import { EyeClosedIcon, EyeOpenIcon } from '../Icons'
import { useUpdatePasswordMutation } from '../../services/settings'
import { addToast } from '../../store/global'
import { validatePassword, passwordRules } from '../../../../utils/validators'
import { SubmitButton } from '../Buttons/index'

export function PasswordUpdate() {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [updatePassword, { isLoading, isSuccess }] = useUpdatePasswordMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          title: 'Success',
          subtext: 'Password has been changed.',
          type: 'success'
        })
      )
    }
  }, [isSuccess])

  const onUpdatePasswordClick = () => {
    if (password === '' || !validatePassword(password) || isLoading) {
      return dispatch(
        addToast({
          title: 'Error detected',
          subtext: 'Please fill out the password field correctly before continuing.',
          type: 'error'
        })
      )
    }
    updatePassword(password)
    setPassword('')
  }

  return (
    <Panel title="Change Password" variant="settings">
      <div className="flex flex-col gap-[24px] justify-self-center justify-items-center pt-[16px] self-start w-full max-w-[344px]">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="new-password"
            className="mb-2 flex gap-[10px] font-semibold text-sm text-gray-600 dark:text-gray-300 leading-5"
          >
            New password
          </label>
          <div className="relative">
            <input
              className={`
                w-full font-[400] text-[16px] font-inter leading-[24px] dark:bg-gray-900 text-base dark:text-neutral-50 text-gray-900 py-[10px] px-[14px] border-[2px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] transition-all duration-200
                ${
                  password === '' || validatePassword(password)
                    ? 'border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 focus:border-[#1c64f2]'
                    : 'border-error-300 hover:border-error-500 focus:border-error-600 dark:border-error-500  dark:hover:border-error-600 dark:focus:border-error-700 focus:ring-0 focus:ring-offset-0'
                }`}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              name="new-password"
              type={showPassword ? 'text' : 'password'}
            />
            <div
              className="absolute top-[12px] right-[15px] cursor-pointer active:scale-90 body text-show-hide fill-base-900 hover:fill-base-400 dark:fill-primary-600 dark:hover:fill-primary-700 transition-all duration-100"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </div>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <div className="relative font-inter font-[400] text-sm text-gray-600 dark:text-white leading-[20px]">
            <div className="mb-1">Ensure that these requirements are met:</div>
            <ol className="space-y-1 pl-4">
              {passwordRules.map((rule, key) => (
                <li className="flex items-center" key={key}>
                  <span
                    className={`mr-2 flex h-4 w-full max-w-[16px] items-center justify-center rounded-full text-base text-white
                      ${rule.pattern.test(password) ? 'bg-primary-500' : 'bg-secondary-200'}
                    `}
                  />
                  <span className="text-xs items-center">{rule.label}</span>
                </li>
              ))}
            </ol>
          </div>
        </div>
        <SubmitButton
          isLoading={isLoading}
          onClick={onUpdatePasswordClick}
          label="Update Password"
        />
      </div>
    </Panel>
  )
}
