export const PageType = {
  Dashboard: 'Dashboard',
  Ads: 'Ads',
  Campaign: 'Campaign',
  Seo: 'Seo',
  Facebook: 'Facebook',
  Billing: 'Billing',
  Settings: 'Settings',
  Contact: 'Contact',
  Password: 'Password',
  Company: 'Company',
  Preferences: 'Preferences',
  Connections: 'Connections',
  Website: 'Website'
}
