import React from 'react'

interface SelectionOptionProps {
  text: string
  onClick: () => void
}

export function SelectionOption({ text, onClick }: SelectionOptionProps) {
  return (
    <li className="px-[6px] py-[2px]">
      <button
        type="button"
        className="break-words block py-[10px] px-[8px] w-full text-center rounded-[6px] group transition duration-100 dark:text-base-50 hover:bg-gray-100 hover:dark:bg-gray-600 active:scale-95"
        role="menuitem"
        onClick={onClick}
      >
        {text}
      </button>
    </li>
  )
}
