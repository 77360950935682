import { api } from './api'
import { Tag } from './tags'

//* Internal cpanel routes which query /cpanel/yourroute, which in turn queries the api
export const cpanelApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendInviteRequest: build.query({
      query: (provider, platform, ids) => ({
        url: 'cpanel/invitecustomer',
        method: 'POST',
        body: JSON.stringify({
          provider,
          platform,
          ids
        })
      }),
      providesTags: (response, error, id) => [{ type: Tag.BillingInvoices, id }]
    }),
    checkCustomerLink: build.mutation({
      query: (provider, platform, ids) => ({
        url: 'cpanel/connectionstatus',
        method: 'POST',
        body: JSON.stringify({
          provider,
          platform,
          ids
        })
      })
    }),
    updateAdsId: build.mutation({
      query: (id, subscriptionId, googleAdsId, facebookAdsId) => ({
        // TODO: convert to platform/provider
        url: 'cpanel/adsid',
        method: 'POST',
        body: JSON.stringify({
          id,
          subscriptionId,
          googleAdsId,
          facebookAdsId
        })
      })
    }),
    checkAccessKey: build.query({
      query: (accesskey) => ({
        url: 'cpanel/accesskey',
        method: 'POST',
        body: {
          accesskey
        }
      })
    }),
    selectCompany: build.mutation({
      query: (companyId) => ({
        url: `cpanel/selectCompany`,
        method: 'POST',
        body: {
          companyId
        }
      })
    })
  })
})

export const {
  useSendInviteRequestQuery,
  useCheckCustomerLinkQuery,
  useUpdateAdsIdQuery,
  useCheckAccessKeyQuery,
  useSelectCompanyMutation
} = cpanelApi
